import { buttonsColors } from './DialogButton.config';
import { ButtonWrapper } from './DialogButton.styles';

export function DialogButton({
  children,
  action,
  color = buttonsColors.default,
}) {
  return (
    <ButtonWrapper color={color} onClick={action}>
      {children}
    </ButtonWrapper>
  );
}
