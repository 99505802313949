import { isWithinInterval, sub } from 'date-fns';

export const variantToNotificationTypeLookup: { [value: string]: string } = {
  feedback: 'Feedback',
  administration: 'Administration',
  information: 'Information',
};

export const isDateWithin24Hours = (date: Date): boolean => {
  const currDate = new Date();
  const range = {
    start: sub(currDate, { hours: 24 }),
    end: currDate,
  };
  return isWithinInterval(date, range);
};
