import React, { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Loader } from '../components/common/Loader';
import { NotFound } from '../containers/NotFound/NotFound';
import {
  PublicTokenAuthenticationProvider,
  StandardAuthenticationProvider,
} from '../utils/AuthorizationHelpers';
import { AuthorizationTypes, routes } from './routes';

const DispatchAuthorizaton = ({
  authType,
  PageComponent,
}: {
  PageComponent: any;
  authType: AuthorizationTypes;
  path: string;
}) => {
  switch (authType) {
    case AuthorizationTypes.Standard:
      return (
        <StandardAuthenticationProvider {...{ Component: PageComponent }} />
      );
    case AuthorizationTypes.PublicToken:
      return (
        <PublicTokenAuthenticationProvider {...{ Component: PageComponent }} />
      );

    default:
      return <PageComponent />;
  }
};

const PageSwitch = React.memo(() => (
  <Routes>
    {Object.entries(routes).map(([, route]) => (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.component ? (
            <Suspense fallback={<Loader />}>
              <DispatchAuthorizaton
                PageComponent={route.component}
                authType={route.authentication}
                path={route.path}
              />
            </Suspense>
          ) : (
            <Outlet />
          )
        }
        // eslint-disable-next-line react/no-children-prop
        children={route.nestedRoutes}
      />
    ))}
    <Route path="*" key="not-found-page" element={<NotFound />} />
  </Routes>
));

export default PageSwitch;
