export const AppPaths = {
  login: '/login',
  logout: '/logout',
  register: '/register',
  myProfile: '/my-profile',
  candidates: '/candidates',
  sharedListId: '/shared-list/:id',
  sharedListPartnerId: '/shared-list/partner/:id',
  employeeId: '/employee/:id',
  employeeCv: '/cv',
  employeeCvId: '/cv/:id',
  listsOfCandidatesForSharing: '/lists-of-candidates-for-sharing',
  users: '/users',
  usersUsers: '/users/users',
  usersPartners: '/users/partners',
  clientListCvPreview: '/cv-preview/list/:listId/cv/:cvId/',
  hrCvPreview: '/cv-preview/hr-list/:listId/cv/:cvId/',
  leadDetail: '/lead/:id/',
  leads: '/leads',
  stats: '/stats/:statsTab',
  sharedEmployeeList: '/shared-employee-list/:id',
  sharedEmployeeListClientId: '/shared-employee-list/:listId/:clientId/',
  home: '/',
  cvs: '/cvs',
  notifications: '/notifications',
};
