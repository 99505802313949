import { buttonsColors } from '../../common/Button/Button.config';
import { InputButtonWrapper } from '../../common/Button/Button.styles';
import { IdegoDialog } from '../../common/IdegoDialog/IdegoDialog.component';
import { StyledButtonsContainer } from '../../common/IdegoDialog/IdegoDialog.styles';
import { Input } from '../../common/Input/Input';

export const LoginForm = ({ handleSubmit }) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    handleSubmit({ email, password });
  };

  return (
    <IdegoDialog dialogTitle="Login" buttonsConfig={{}} showLogo>
      <form onSubmit={handleFormSubmit}>
        <Input label="Email" name="email" type="email" required autocomplete />
        <Input
          label="Password"
          name="password"
          type="password"
          required
          autocomplete
        />
        <StyledButtonsContainer>
          <InputButtonWrapper color={buttonsColors.default} value="Sign in" />
        </StyledButtonsContainer>
      </form>
    </IdegoDialog>
  );
};
