import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin: 15px 0px 5px 0px;
  width: 100%;
`;

export const StyledTextField = styled.textarea`
  border: 1px solid #d7ddea;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13;
  padding: 15px;
  width: 100%;

  &:focus {
    outline-width: 0;
  }
`;
