import React from 'react';
import { useDropzone } from 'react-dropzone';
import { StyledDropzone } from './Dropzone.styles';

export const Dropzone = (props) => {
  const { handleFileSelection } = props;
  const onDrop = handleFileSelection;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <StyledDropzone {...getRootProps({ isDragActive })}>
      <input {...getInputProps()} />
      <span>Drag & Drop files: PDF, JPG, PNG here or click to upload</span>
    </StyledDropzone>
  );
};
