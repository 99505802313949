import { createAsyncThunk } from '@reduxjs/toolkit';
import { RegisteruserParams } from '../../config/Api.types';
import { PersistedStoreKeys } from '../../config/PersistedStoreKeys';
import {
  API_ALL_USERS,
  API_CHANGE_PASSWORD,
  API_HR_USERS,
  API_LOGIN_URL,
  API_PARTNER_USERS,
  API_REGISTER_URL,
  API_SALES_USERS,
  API_URL,
} from '../../config/urls';
import { UserState } from '../../store/types/Store.user.types';
import { getAuthHeader, handleResponse } from '../../utils/api';
import { raiseToast } from '../../utils/raiseToasts';

const loginThunk = createAsyncThunk<UserState>(
  'user/login',
  async (data, { dispatch }) => {
    const request = fetch(API_URL + API_LOGIN_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch); // I'm not sure if it throws an error here

    if (response.token && response.user) {
      localStorage.setItem(PersistedStoreKeys.token, response.token); //todo: make a localStorage service
      localStorage.setItem(
        PersistedStoreKeys.user,
        JSON.stringify(response.user),
      );

      return response;
    }
    throw new Error();
  },
);

const saveStateThunk = createAsyncThunk('user/saveState', async (data) => {
  localStorage.setItem(PersistedStoreKeys.userFilters, JSON.stringify(data));
  return data;
});

const logoutThunk = createAsyncThunk('user/logout', async () => {
  localStorage.removeItem(PersistedStoreKeys.user);
  localStorage.removeItem(PersistedStoreKeys.token);
  raiseToast.info('You were logged out.');
});

const registerThunk = createAsyncThunk<RegisteruserParams>(
  'user/register',
  async (data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_REGISTER_URL, {
      method: 'POST',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully registered a new user.');
    }

    return response;
  },
);

const changePasswordThunk = createAsyncThunk(
  'user/changePassword',
  async (data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_CHANGE_PASSWORD, {
      method: 'PUT',
      headers: {
        ...getAuthHeader(getState()),
      },
      body: JSON.stringify(data),
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully changed password.');
    }
  },
);

const getHRUsersThunk = createAsyncThunk(
  'user/getHRUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_HR_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getSalesUsersThunk = createAsyncThunk(
  'user/getSalesUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_SALES_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getPartnerUsersThunk = createAsyncThunk(
  'user/getPartnerUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(API_URL + API_PARTNER_USERS, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const getUsersThunk = createAsyncThunk(
  'users/getUsers',
  async (_data, { getState, dispatch }) => {
    const request = fetch(`${API_ALL_USERS}`, {
      method: 'GET',
      headers: getAuthHeader(getState()),
    });
    return await handleResponse(request, dispatch);
  },
);

const deactivateUser = createAsyncThunk(
  'users/deactivateUser',
  async (data: { userId: string }, { getState, dispatch }) => {
    const request = fetch(`${API_ALL_USERS}${data.userId}`, {
      method: 'DELETE',
      headers: getAuthHeader(getState()),
    });

    const result = await handleResponse(request, dispatch);
    raiseToast.success('Successfully deactivated user');
    return result;
  },
);

const updateUserThunk = createAsyncThunk(
  'users/updateUser',
  async (
    data: { userId: string; payload: UserState },
    { getState, dispatch },
  ) => {
    const request = fetch(`${API_ALL_USERS}${data.userId}/`, {
      method: 'PATCH',
      body: JSON.stringify(data.payload),
      headers: {
        ...getAuthHeader(getState()),
      },
    });

    const response = await handleResponse(request, dispatch);
    if (response) {
      raiseToast.success('Successfully updated user info.');
    }
  },
);

export const userThunks = {
  loginThunk,
  registerThunk,
  logoutThunk,
  saveStateThunk,
  changePasswordThunk,
  getHRUsersThunk,
  getUsersThunk,
  deactivateUser,
  updateUserThunk,
  getSalesUsersThunk,
  getPartnerUsersThunk,
};
