import styled from 'styled-components';
import { textColor } from './DialogButton.config';

export const ButtonWrapper = styled.button`
  background-color: ${({ color }) => color};
  border: none;
  border-radius: 3px;
  color: ${textColor.white};
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 16px;
  transition-duration: 0.2s;

  &:hover {
    background-color: ${({ color }) => {
      return color === '#3cc2d9' ? '#5CD6EB' : color;
    }};
    cursor: pointer;
  }
  &:active {
    background-color: ${({ color }) => {
      return color === '#3cc2d9' ? '#26A8BE' : color;
    }};
  }
`;
