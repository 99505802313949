import styled from 'styled-components';

export const StyledNavbar = styled.div`
  align-items: center;
  background-color: #101840;
  display: flex;
  justify-content: space-between;
  min-height: 55px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  @media print {
    display: none;
  }
`;

export const StyledLogo = styled.img`
  margin-left: 65px;
  margin-right: 30px;
  max-height: 32px;
  @media screen and (max-width: 1050px) {
    margin-left: 10px;
  }
`;

export const StyledMenuButton = styled.div`
  color: white;
  cursor: pointer;
  margin-left: auto;
  padding: 12px 20px;
  &:hover {
    background-color: #5dccdf;
    color: #fff;
  }
`;

export const RightIconsWrapper = styled.div`
  display: none;
  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledBasketButton = styled.div`
  color: white;
  cursor: pointer;
  margin-left: auto;
  padding: 12px 20px;

  & .MuiBadge-badge {
    background-color: #3cc2d9;
    color: white;
    font-size: 12px;
    min-height: 20px;
    min-width: 20px;
  }
`;
