import styled from 'styled-components';

export const StyledDropzone = styled.div`
  align-items: center;
  background-color: #f7f8f9;
  border: 1px dashed;
  border-color: ${(props) => (props.isDragActive ? '#101840' : '#e6ebef')};
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 20px 0;
  &:hover {
    cursor: pointer;
  }
  & > span {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-size: 13px;
  }
`;
