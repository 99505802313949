import { Box } from '@mui/material';
import { StyledNotificationPopperHeaderButton } from '../Notifications.styles';
import { NotificationPopperHeaderProps } from '../Notifications.types';

const NotificationPopperHeader = ({
  height,
  onReadAllClick,
}: NotificationPopperHeaderProps) => {
  /* On the figma design there is some wierd font used for only this single button
    (Google Lato). If this component looks a little bit wierd, this is the reason
   */
  return (
    <Box sx={{ display: 'flex', height }}>
      <StyledNotificationPopperHeaderButton onClick={onReadAllClick}>
        Mark all as read
      </StyledNotificationPopperHeaderButton>
    </Box>
  );
};

export default NotificationPopperHeader;
