import styled from 'styled-components';
import { textColor } from './Button.config';

type ButtonWrapperProps = {
  color?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background-color: ${({ color, disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.2)' : color};
  border: none;
  border-radius: 3px;
  color: ${textColor.white};
  display: ${({ fullWidth }) => (fullWidth ? 'block' : 'inline')};
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  padding: 8px 16px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  transition-duration: 0.2s;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  &:hover {
    background-color: ${({ color }) => {
      return color === '#3cc2d9' ? '#5CD6EB' : color;
    }};
    cursor: pointer;
  }
  &:active {
    background-color: ${({ color }) => {
      return color === '#3cc2d9' ? '#26A8BE' : color;
    }};
  }
`;

export const InputButtonWrapper = styled.input.attrs({ type: 'submit' })`
  background-color: ${({ color }) => color};
  color: ${textColor.white};
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: 400;
  font-family: Inter;
  line-height: 16px;
  border: none;
  transition-duration: 0.2s;

  &:hover {
    cursor: pointer;
  }
`;
