import Radio from '@mui/material/Radio';
import { FormLabel } from '../FormLabel/FormLabel';
import {
  OptionWrapper,
  StyledInputContainer,
  StyledRadioLabel,
} from './RadioInput.styles';

export const RadioInput = ({
  label,
  name,
  handleInputChange,
  options,
  color,
  disabled = false,
}) => (
  <StyledInputContainer disabled={disabled}>
    {label && <FormLabel htmlFor={name} label={label} />}
    {options.map((option) => (
      <OptionWrapper key={option.name}>
        <Radio
          name={name}
          value={option.value}
          onChange={handleInputChange}
          checked={option.checked}
          sx={{
            padding: 0,
            '&.Mui-checked': {
              color: color ?? '#004A62',
            },
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          }}
        />
        <StyledRadioLabel>{option.name}</StyledRadioLabel>
      </OptionWrapper>
    ))}
  </StyledInputContainer>
);
