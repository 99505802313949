import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { userThunks } from '../User.thunk';
import { RegisterForm } from '../../../components/User/RegisterForm/RegisterForm.component';
import { raiseToast } from '../../../utils/raiseToasts';

const Register = ({ partnerNames, closeModal }) => {
  const dispatch = useDispatch();
  const [currNewUser, setCurrNewUser] = useState({});
  const handleSubmit = async (data) => {
    if (data.password === data.password2) {
      const result = await dispatch(userThunks.registerThunk(data));
      if (result.type === userThunks.registerThunk.fulfilled.type) {
        dispatch(userThunks.getUsersThunk());
        closeModal();
      } else {
        setCurrNewUser(data);
      }
    } else {
      raiseToast.error("Provided passwords don't match");
    }
  };

  return (
    <RegisterForm
      partnerNames={partnerNames}
      handleSubmit={handleSubmit}
      currForm={currNewUser}
      closeModal={closeModal}
    />
  );
};

export default Register;
