import SearchIcon from '@mui/icons-material/Search';
import { KeyboardEvent, useEffect, useState } from 'react';
import { SearchFieldProps } from './SearchField.types';
import { InputIcon, Root, StyledInput } from './SearсhFiled.styles';

export const SearchField = ({
  onSubmit,
  initialValue,
  onChange,
}: SearchFieldProps) => {
  const [inputValue, setInputValue] = useState<string>(initialValue);

  const handleUserInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit && onSubmit(inputValue);
    } else {
      onChange && onChange(inputValue);
    }
  };

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <Root>
      <InputIcon
        onClick={() => onSubmit && onSubmit(initialValue)}
        style={{ cursor: 'pointer' }}
      >
        <SearchIcon style={{ color: '#b5bfc8', fontSize: '25px' }} />
      </InputIcon>
      <StyledInput
        placeholder="Search"
        onKeyUp={(e) => handleUserInput(e)}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
    </Root>
  );
};
