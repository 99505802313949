/* eslint-disable import/no-cycle */
import React, { LazyExoticComponent } from 'react';
import UsersPageRoutes from '../containers/UsersPage/UsersPageRoutes';
import { AppPaths } from './AppPaths';

// Lazy imports of each screen
const Login = React.lazy(
  () => import('../containers/User/Login/Login.component'),
);
const Logout = React.lazy(() => import('../containers/User/Logout.component'));
const Register = React.lazy(
  () => import('../containers/User/Register/Register.component'),
);
const MyProfile = React.lazy(
  () => import('../containers/User/MyProfile/MyProfile.component'),
);
const Employees = React.lazy(
  () => import('../containers/Employee/Employees.component'),
);
const EmployeePage = React.lazy(
  () => import('../containers/Employee/EmployeePage/EmployeePage'),
);
const CV = React.lazy(() => import('../containers/CV/CV.component'));
const Lists = React.lazy(() => import('../containers/Lists/Lists'));
const ClientList = React.lazy(
  () => import('../containers/ClientList/ClientList'),
);
const HrList = React.lazy(
  () => import('../containers/Lists/HrLists/HrList/HrList'),
);
const LeadsTable = React.lazy(
  () => import('../containers/Leads/LeadsTable/LeadsTable'),
);
const LeadDetail = React.lazy(
  () => import('../containers/Leads/LeadDetail/LeadDetail'),
);
const Stats = React.lazy(() => import('../containers/Stats/Stats.component'));
const Main = React.lazy(() => import('../containers/Main/Main'));

const UsersPage = React.lazy(
  () => import('../containers/UsersPage/UsersPage.component'),
);
const ClientListCvPreview = React.lazy(
  () => import('../containers/CV/CvPreview/ClientListView/ClientListCvPreview'),
);
const HrCvPreview = React.lazy(
  () => import('../containers/CV/CvPreview/HrCvPreview/HrCvPreview'),
);
const ClientListDetailsView = React.lazy(
  () => import('../containers/ClientList/ClientListHrView/ClientListHrView'),
);
const NotificationPage = React.lazy(
  () => import('../components/common/Notifications/Page/NotificationPage'),
);

export enum AuthorizationTypes {
  Standard, // standard session cookie / token
  PublicToken, // special authorization type for external clients
  None, // no authorization
}

export interface RouteType {
  path: string;
  nestedRoutes?: JSX.Element[];
  component?: LazyExoticComponent<any>;
  authentication: AuthorizationTypes;
}

export const routes: { [key: string]: RouteType } = {
  login: {
    path: AppPaths.login,
    component: Login,
    authentication: AuthorizationTypes.None,
  },
  logout: {
    path: AppPaths.logout,
    component: Logout,
    authentication: AuthorizationTypes.None,
  },
  register: {
    path: AppPaths.register,
    component: Register,
    authentication: AuthorizationTypes.Standard,
  },
  myProfile: {
    path: AppPaths.myProfile,
    component: MyProfile,
    authentication: AuthorizationTypes.Standard,
  },
  employees: {
    path: AppPaths.candidates,
    component: Employees,
    authentication: AuthorizationTypes.Standard,
  },
  clientListLogin: {
    path: AppPaths.sharedListId,
    component: ClientList,
    authentication: AuthorizationTypes.PublicToken,
  },
  clientListPartner: {
    path: AppPaths.sharedListPartnerId,
    component: ClientList,
    authentication: AuthorizationTypes.Standard,
  },
  employeeCvs: {
    path: AppPaths.employeeId,
    component: EmployeePage,
    authentication: AuthorizationTypes.Standard,
  },
  createCv: {
    path: AppPaths.employeeCv,
    component: CV,
    authentication: AuthorizationTypes.Standard,
  },
  editCv: {
    path: AppPaths.employeeCvId,
    component: CV,
    authentication: AuthorizationTypes.Standard,
  },
  listsOfCandidatesForSharing: {
    path: AppPaths.listsOfCandidatesForSharing,
    component: Lists,
    authentication: AuthorizationTypes.Standard,
  },
  users: {
    path: AppPaths.users,
    component: UsersPage,
    nestedRoutes: UsersPageRoutes,
    authentication: AuthorizationTypes.Standard,
  },

  listOfCandidatesForSharing: {
    path: AppPaths.sharedEmployeeList,
    component: HrList,
    authentication: AuthorizationTypes.Standard,
  },
  clientListCvPreview: {
    path: AppPaths.clientListCvPreview,
    component: ClientListCvPreview,
    authentication: AuthorizationTypes.None,
  },
  hrCvPreview: {
    path: AppPaths.hrCvPreview,
    component: HrCvPreview,
    authentication: AuthorizationTypes.Standard,
  },
  leadDetail: {
    path: AppPaths.leadDetail,
    component: LeadDetail,
    authentication: AuthorizationTypes.Standard,
  },
  leads: {
    path: AppPaths.leads,
    component: LeadsTable,
    authentication: AuthorizationTypes.Standard,
  },
  stats: {
    path: AppPaths.stats,
    component: Stats,
    authentication: AuthorizationTypes.Standard,
  },
  clientList: {
    path: AppPaths.sharedEmployeeListClientId,
    component: ClientListDetailsView,
    authentication: AuthorizationTypes.Standard,
  },
  main: {
    path: AppPaths.home,
    component: Main,
    authentication: AuthorizationTypes.Standard,
  },
  notifications: {
    path: AppPaths.notifications,
    component: NotificationPage,
    authentication: AuthorizationTypes.Standard,
  },
};
