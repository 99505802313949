import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: white;
  }
`;

export const StyledContainer = styled.div`
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin: 50px auto;
  padding: 50px 80px 50px 80px;
  width: 100%;

  @media (min-width: 700px) {
    min-width: 450px;
    max-width: 700px;
  }

  @media (max-width: 699px) {
    max-width: 450px;
    padding: 25px 30px 25px 30px;
  }
`;

export const StyledNav = styled.div`
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 20px;

  &:before {
    border: 2px solid #3cc2d9;
    content: '';
    display: block;
    margin-top: 81px;
    position: absolute;
    width: 80px;
  }
`;

export const StyledCompanyLogoWrapper = styled.div`
  height: 30px;
  width: 80px;
`;

export const StyledCompanyMottoWrapper = styled.div`
  height: 40px;
  width: 140px;
`;

export const StyledNavImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 21px;
  margin-top: 30px;
  width: 100%;

  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

export const StyledFormTitle = styled.h3`
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
`;
