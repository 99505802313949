import { combineReducers, configureStore } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import { reducer as apiControllerSlice } from '../containers/ApiController/ApiController.slice';
import { reducer as clientListSlice } from '../containers/ClientList/redux/ClientList.slice';
import { reducer as clientListCandidateHistorySlice } from '../containers/ClientList/redux/ClientListCandidateHistory.slice';
import { reducer as clientListConfigSlice } from '../containers/ClientList/redux/ClientListConfig.slice';
import { reducer as clientListCredentialsSlice } from '../containers/ClientList/redux/ClientListCredentials.slice';
import { reducer as clientListMessageSlice } from '../containers/ClientList/redux/ClientListMessage.slice';
import { reducer as clientListProfileSlice } from '../containers/ClientList/redux/ClientListProfile.slice';
import { reducer as clientListProfileNoteSlice } from '../containers/ClientList/redux/ClientListProfileNote.slice';
import { reducer as cvSlice } from '../containers/CV/CV.slice';
import { reducer as additionalInfoSlice } from '../containers/CV/Sections/AdditionalInfo/AdditionalInfo.slice';
import { reducer as certificatesSlice } from '../containers/CV/Sections/Certificates/Certificates.slice';
import { reducer as customSectionSlice } from '../containers/CV/Sections/CustomSection/CustomSection.slice';
import { reducer as educationSlice } from '../containers/CV/Sections/Education/Education.slice';
import { reducer as experienceSlice } from '../containers/CV/Sections/Experience/Experience.slice';
import { reducer as languagesSlice } from '../containers/CV/Sections/Languages/Languages.slice';
import { reducer as positionSlice } from '../containers/CV/Sections/Position/Position.slice';
import { reducer as sideProjectsSlice } from '../containers/CV/Sections/SideProjects/SideProjects.slice';
import { reducer as technologiesSlice } from '../containers/CV/Sections/Technologies/Technologies.slice';
import { reducer as employeePageSlice } from '../containers/Employee/EmployeePage/redux/EmployeePage.slice';
import { reducer as employeePageCvsValidationSlice } from '../containers/Employee/EmployeePage/redux/EmployeePageCvsValidation.slice';
import { reducer as employeesSlice } from '../containers/Employee/Employees.slice';
import { reducer as leadDetailsSlice } from '../containers/Leads/redux/LeadDetails.slice';
import { reducer as leadProfileCandidateHistorySlice } from '../containers/Leads/redux/LeadProfileCandidateHistory.slice';
import { reducer as leadProfileCandidatesSlice } from '../containers/Leads/redux/LeadProfileCandidates.slice';
import { reducer as leadProfilesSlice } from '../containers/Leads/redux/LeadProfiles.slice';
import { reducer as leadsFiltersSlice } from '../containers/Leads/redux/LeadsFilters.slice';
import { reducer as leadsListSlice } from '../containers/Leads/redux/LeadsList.slice';
import { reducer as candidateReasonTagsSlice } from '../containers/Leads/redux/tags/CandidateReasonTags.slice';
import { reducer as industryTagsSlice } from '../containers/Leads/redux/tags/IndustryTags.slice';
import { reducer as leadTagsSlice } from '../containers/Leads/redux/tags/LeadTags.slice';
import { reducer as mainTechnologiesSlice } from '../containers/Leads/redux/tags/MainTechnologiesTags.slice';
import { reducer as profileReasonTagsSlice } from '../containers/Leads/redux/tags/ProfileReasonTags.slice';
import { reducer as profileTagsSlice } from '../containers/Leads/redux/tags/ProfileTags.slice';
import { reducer as hrListsSlice } from '../containers/Lists/HrLists/redux/HrLists.slice';
import { reducer as hrListsCandidatesSlice } from '../containers/Lists/HrLists/redux/HrListsCandidates.slice';
import { reducer as hrListsFiltersSlice } from '../containers/Lists/HrLists/redux/HrListsFilters.slice';
import { reducer as mainPageThunks } from '../containers/Main/MainPage.slice';
import { reducer as notificationsThunks } from '../containers/Notifications/Notification.slice';
import { reducer as partnerThunks } from '../containers/Partner/Partner.slice';
import { reducer as loginSlice } from '../containers/User/User.slice';
import { undoableConfig } from './undoable';

export const store = configureStore({
  reducer: {
    user: loginSlice,
    employees: employeesSlice,
    cv: undoable(
      combineReducers({
        config: cvSlice,
        data: combineReducers({
          experience: experienceSlice,
          technologies: technologiesSlice,
          languages: languagesSlice,
          education: educationSlice,
          position: positionSlice,
          additionalInfo: additionalInfoSlice,
          sideProjects: sideProjectsSlice,
          customSections: customSectionSlice,
          certificates: certificatesSlice,
        }),
      }),
      undoableConfig,
    ),
    clientListRelated: combineReducers({
      clientList: combineReducers({
        list: clientListSlice,
        messages: clientListMessageSlice,
        candidateHistory: clientListCandidateHistorySlice,
      }),
      clientListProfile: combineReducers({
        list: clientListProfileSlice,
        notes: clientListProfileNoteSlice,
      }),
      config: clientListConfigSlice,
      credentials: clientListCredentialsSlice,
    }),
    hrLists: combineReducers({
      list: hrListsSlice,
      employees: hrListsCandidatesSlice,
      filter: hrListsFiltersSlice,
    }),
    leadsRelated: combineReducers({
      list: leadsListSlice,
      profiles: leadProfilesSlice,
      candidates: combineReducers({
        list: leadProfileCandidatesSlice,
        history: leadProfileCandidateHistorySlice,
      }),
      tags: combineReducers({
        lead: leadTagsSlice,
        industry: industryTagsSlice,
        profile: profileTagsSlice,
        profile_reason: profileReasonTagsSlice,
        candidate_reason: candidateReasonTagsSlice,
        main_technologies: mainTechnologiesSlice,
      }),
      details: leadDetailsSlice,
      filters: leadsFiltersSlice,
    }),
    home: mainPageThunks,
    partner: partnerThunks,
    apiController: apiControllerSlice,
    notifications: notificationsThunks,
    employeePage: combineReducers({
      data: employeePageSlice,
      validated_cvs: employeePageCvsValidationSlice,
    }),
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
