import { generatePath, useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes';
import { StyledNotificationPopperFooterButton } from '../Notifications.styles';

const NotificationPopperFooter = ({ height }: { height: string }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(generatePath(routes.notifications.path));
  };

  return (
    <StyledNotificationPopperFooterButton
      style={{ height }}
      onClick={handleOnClick}
    >
      View all
    </StyledNotificationPopperFooterButton>
  );
};

export default NotificationPopperFooter;
