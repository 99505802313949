import { DisabledLabel, StyledLabel } from './FormLabel.styles';
import { FormLabelProps } from './FormLabel.types';

export const FormLabel = ({
  label,
  htmlFor,
  disabled,
  required,
}: FormLabelProps) => {
  if (disabled) {
    return <DisabledLabel htmlFor={htmlFor}>{label}</DisabledLabel>;
  }
  if (required) {
    return (
      <StyledLabel htmlFor={htmlFor}>
        <span>
          {label}
          <span style={{ color: 'red', fontSize: '0.8rem' }}>*</span>
        </span>
      </StyledLabel>
    );
  }

  return <StyledLabel htmlFor={htmlFor}>{label}</StyledLabel>;
};
