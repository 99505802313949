import styled from 'styled-components';

export const FilesListItem = styled.li`
  border: 1px solid #e6ebef;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: 24px 33px;
  & > span {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-size: 13px;
  }
  &:last-child {
    border-bottom: 1px solid #e6ebef;
  }
  & > a {
    color: currentColor;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-size: 13px;
    text-decoration: none;
  }
`;

export const FilesListRemoveButton = styled.span`
  color: #f85454;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const FilesListItemTitle = styled.span`
  align-items: center;
  display: flex;
`;
