import { PropsWithChildren } from 'react';
import { ButtonWrapper, StyledAddIcon } from './AddButton.styles';
import { AddButtonProps } from './AddButton.types';

export function AddButton({
  children,
  action,
  marginLeft,
}: PropsWithChildren<AddButtonProps>) {
  return (
    <ButtonWrapper marginLeft={marginLeft} onClick={action}>
      <StyledAddIcon />
      {children}
    </ButtonWrapper>
  );
}
