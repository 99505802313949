/* eslint-disable radar/no-duplicate-string */
import { useState } from 'react';
import { raiseToast } from '../../../utils/raiseToasts';
import { AutocompleteInput } from '../../common/AutocompleteInput';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Input } from '../../common/Input/Input';
import { RadioInput } from '../../common/RadioInput/RadioInput.component';
import { AutocompleteContainer, FormWrapper } from './EditForm.styles';

export const EditForm = ({ userToEdit, handleSubmit, handleBack, ref }) => {
  const [registerData, setRegisterData] = useState({
    username: userToEdit.username,
    department: userToEdit.department,
  });

  const [name] = useState(userToEdit.partner?.partner_name);

  const ACCESS_OPTIONS = [
    {
      name: 'HR',
      value: 'Human Resources',
      checked: registerData.department
        ? registerData.department === 'Human Resources'
        : userToEdit.department === 'Human Resources',
    },
    {
      name: 'Sales',
      value: 'Sales',
      checked: registerData.department
        ? registerData.department === 'Sales'
        : userToEdit.department === 'Sales',
    },
    {
      name: 'External Client',
      value: 'External Client',
      checked: registerData.department
        ? registerData.department === 'External Client'
        : userToEdit.department === 'External Client',
    },
    {
      name: 'Partner',
      value: 'Partner',
      checked: registerData.department
        ? registerData.department === 'Partner'
        : userToEdit.department === 'Partner',
    },
  ];

  const handleInputChange = (e) => {
    if (
      userToEdit.department !== 'Partner' &&
      e.target.name === 'department' &&
      e.target.value === 'Partner'
    ) {
      raiseToast.error(
        "It's impossible to change access type to partner. Please create new account.",
      );
    } else {
      setRegisterData({
        ...registerData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const buttonsConfig = {
    primaryButton: {
      label: 'Save',
      action: () => handleSubmit(registerData),
    },
  };

  return (
    <Dialog
      dialogTitle="Edit user"
      buttonsConfig={buttonsConfig}
      handleClose={handleBack}
      ref={ref}
    >
      <FormWrapper>
        <Input
          label="Username"
          name="username"
          required
          defaultValue={userToEdit.username}
          value={registerData.username}
          onChange={handleInputChange}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          disabled
          value={userToEdit.email}
          onChange={handleInputChange}
        />
        <RadioInput
          label="Access type"
          name="department" /* need to change the requests from department to access_type */
          defaultValue={userToEdit.email}
          value={registerData.department}
          required
          handleInputChange={handleInputChange}
          options={ACCESS_OPTIONS}
        />
        {registerData.department === 'Partner' && (
          <AutocompleteContainer>
            <AutocompleteInput
              label="Partner's name"
              name="partner_name"
              freeSolo
              disabled
              value={name || ''}
            />
          </AutocompleteContainer>
        )}
      </FormWrapper>
    </Dialog>
  );
};
