export const buttonsColors = {
  red: '#FF0A0A',
  green: '#21CA1E',
  blue: '#5196FF',
  default: '#3cc2d9',
  grey: '#9FA3B3',
};

export const textColor = {
  white: '#ffffff',
};
