import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 150px;
  position: relative;
  width: 100%;
  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid #b5bfc8;
  border-radius: 3px;
  font-family: 'Neue Haas Grotesk Display Pro', 'Roboto', 'Helvetica', 'Arial',
    sans-serif;
  font-size: 12px;
  font-weight: 200;
  height: 46px;
  padding: 5px 40px;
  width: 100%;

  &::placeholder {
    color: #8d8d8d;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 686px) {
    width: 100%;
    max-width: 686px;
  }
`;

export const InputIcon = styled.span`
  left: 10px;
  position: absolute;
  top: 11px;
`;
