import styled from 'styled-components';

export const StyledLabel = styled.label`
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 6px;
  padding-right: 5px;
`;

export const DisabledLabel = styled.label`
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 6px;
  opacity: 0.2;
  padding-right: 5px;
`;
