import { useState } from 'react';
import { AutocompleteInput } from '../../common/AutocompleteInput';
import { Dialog } from '../../common/Dialog/DEPR_Dialog';
import { Input } from '../../common/Input/Input';
import { RadioInput } from '../../common/RadioInput/RadioInput.component';
import { AutocompleteContainer, FormWrapper } from './RegisterForm.styles';

const ACCESS_OPTIONS = [
  {
    name: 'HR',
    value: 'Human Resources',
    checked: false,
  },
  {
    name: 'Sales',
    value: 'Sales',
    checked: false,
  },
  {
    name: 'External Client',
    value: 'External Client',
    checked: false,
  },
  {
    name: 'Partner',
    value: 'Partner',
    checked: false,
  },
];

export const RegisterForm = ({
  partnerNames,
  handleSubmit,
  closeModal,
  currForm,
  ref,
}) => {
  const [registerData, setRegisterData] = useState({
    username: currForm.username ?? '',
    email: currForm.email ?? '',
    password: currForm.password ?? '',
    password2: currForm.password2 ?? '',
    department: currForm.department ?? '',
  });
  const [name, setName] = useState(registerData.partner_name);

  const handleInputChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'partner_name') {
      setName(e.target.value);
    }
  };

  const handleRadioInputChange = (e) => {
    setRegisterData({
      ...registerData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'department') {
      ACCESS_OPTIONS.forEach((option) => {
        option.checked = option.value === e.target.value;
      });
    }
  };

  const handlePartnerNameChange = (e) => {
    const partnerName = e.target.innerText;
    setRegisterData({
      ...registerData,
      partner_name: partnerName,
    });
    setName(partnerName);
  };

  const buttonsConfig = {
    primaryButton: {
      label: 'Register',
      action: () => {
        handleSubmit(registerData);
      },
    },
  };

  return (
    <Dialog
      dialogTitle="Register new user"
      buttonsConfig={buttonsConfig}
      handleClose={closeModal}
      ref={ref}
    >
      <FormWrapper>
        <Input
          label="Username"
          name="username"
          required
          value={registerData.username}
          onChange={handleInputChange}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          required
          value={registerData.email}
          onChange={handleInputChange}
        />
        <Input
          label="Password"
          name="password"
          type="password"
          required
          value={registerData.password}
          onChange={handleInputChange}
        />
        <Input
          label="Confirm password"
          name="password2"
          type="password"
          required
          value={registerData.password2}
          onChange={handleInputChange}
        />
        <RadioInput
          label="Access type"
          name="department" /* need to change the requests from department to access_type */
          value={registerData.department}
          required
          handleInputChange={handleRadioInputChange}
          options={ACCESS_OPTIONS}
        />
        {registerData.department === 'Partner' && (
          <AutocompleteContainer>
            <AutocompleteInput
              label="Partner's name"
              name="partner_name"
              freeSolo
              options={partnerNames}
              value={name || ''}
              handleInputChange={handlePartnerNameChange}
              handleCustomOption={handleInputChange}
            />
          </AutocompleteContainer>
        )}
      </FormWrapper>
    </Dialog>
  );
};
