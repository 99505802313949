import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    width: '100%',
    minWidth: '300px',
    border: '1px solid #d7ddea',
    borderRadius: 3,
    height: 45,

    '& .MuiAutocomplete-endAdornment': {
      position: 'relative',
      marginLeft: 'auto',
      right: 0,
      paddingTop: 0,
    },
    '& .MuiAutocomplete-input': {
      fontSize: 13,
      fontFamily: 'Neue Haas Grotesk Display Pro',
    },
    '& .MuiInputBase-input-MuiOutlinedInput-input': {
      height: 10,
      margin: 0,
      padding: '0px !important',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      height: 45,
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px 0px 0px 9px !important',
    },
  },
}));

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
  margin-bottom: 20px;
`;

export const StyledSpan = styled.span`
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 13px;
`;
