/* eslint-disable complexity */
import { ToastContent } from '../components/Toasts/ToastContent.component';
import { API_EMPLOYEES_URL } from '../config/urls';
import { actions as apiControllerActions } from '../containers/ApiController/ApiController.slice';
import { EResponseCode } from '../containers/ApiController/ApiController.types';
import { raiseToast } from './raiseToasts';

const APP_JSON = 'application/json';

export const handleResponse = async (request, dispatch) => {
  let response;
  try {
    response = await request;
  } catch (error) {
    if (error.message === 'Failed to fetch') {
      raiseToast.error('Server error occured. Please try again later.');
    } else {
      raiseToast.error(JSON.stringify(error));
    }
    throw error;
  }

  if (response.status === 401) {
    raiseToast.error('Page unavailable for not logged in users.');
    dispatch &&
      dispatch(
        apiControllerActions.setResponseCode(EResponseCode.Unauthenticated),
      );

    throw new Error();
  }

  if (response.status === 403) {
    const contentType = response.headers.get('content-type');
    const jsonResponse = contentType && contentType.includes(APP_JSON);
    const jsonContent = jsonResponse
      ? await response.json()
      : "Logged in user doesn't have permission to use this page.";
    if (jsonContent.detail?.includes('CSRF')) {
      raiseToast.error('Error occurred, please clear cookies.');
    } else {
      raiseToast.error(jsonContent.message);
    }
    throw new Error();
  }

  if (response.status === 500) {
    raiseToast.error('Server error occured. Please try again later.');
    throw new Error();
  }

  if (response.status === 404) {
    const jsonContent = await response.json();
    if (jsonContent.desc) {
      handleResponseErrors(response, jsonContent);
    } else {
      raiseToast.error("Resource doesn't exist.");
    }

    throw new Error();
  }

  if (response.status === 405) {
    raiseToast.error("You're not allowed to do this.");
    throw new Error();
  }

  if (response.status === 204) {
    return true;
  }

  const contentType = response.headers.get('content-type');
  const jsonResponse = contentType && contentType.includes(APP_JSON);

  if (!jsonResponse && !response.ok) {
    throw new Error();
  }

  if (!jsonResponse && response.ok) {
    return response.blob();
  }

  const jsonContent = await response.json();
  if (!response.ok) {
    handleResponseErrors(response, jsonContent);
  }
  return jsonContent;
};

const handleResponseErrors = (response, errors) => {
  if (errors.length) {
    errors.forEach((error) => {
      raiseToast.error(
        <ToastContent title={error.group} content={error.desc} />,
      );
    });
  } else {
    raiseToast.error(
      <ToastContent title={errors.group} content={errors.desc} />,
    );
  }
  if (response.url === `${API_EMPLOYEES_URL}export/`) {
    raiseToast.warn('Candidates exported with errors.');
  }
  throw new Error();
};

export const getAuthHeader = (state) => ({
  'Authorization-Token': `Token ${state.user.token}`,
  'Content-Type': APP_JSON,
});

export const getAuthHeaderMultipart = (state) => ({
  'Authorization-Token': `Token ${state.user.token}`,
  // `multipart/form-data` Content-Type will be assigned automatically by `fetch()`
  // when file is attached to request, with correct boundary.
  // It will prevent following error:
  // https://stackoverflow.com/questions/35457777/multipart-form-parse-error-invalid-boundary-in-multipart-none
});

export const getExternalClientAuthHeader = (state) => ({
  'Content-Type': APP_JSON,
  'External-Access-Token': state.clientListRelated.credentials.token || '',
});
