import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 35px 0px 15px;
  width: 100%;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

export const StyledVerticalLinks = styled.div`
  background-color: #ffffff;
  display: none;
  height: 100vh;
  position: fixed;
  top: 50px;
  width: 100vw;
  z-index: 100;
  @media screen and (max-width: 1050px) {
    display: flex;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 270px;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    box-shadow: 0px 4px 40px #edeff3;
  }
`;

export const MainNavSection = styled.div`
  display: flex;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    margin: 0px 36px 0px 36px;
  }
`;

export const StyledBreak = styled.hr`
  display: hidden;
  @media screen and (max-width: 1050px) {
    display: inherit;
    margin: auto 36px 0 36px;
    border-top: 2px solid #b5bfc8;
  }
`;

export const UserSection = styled.div`
  display: flex;
  margin-right: 17px;
  @media screen and (max-width: 1050px) {
    margin: 0 36px 10px 36px;
    justify-content: center;
    gap: 12px;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  color: #101840;
  cursor: pointer;
  font-size: 20px;
  margin: 14px 24px 20px auto;
`;

export const Underline = styled.div`
  background-color: #7ee2f2;
  display: none;
  height: 4px;
  left: 0px;
  position: absolute;
  top: 51px;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 1050px) {
    left: -19px;
    top: 23px;
    height: 10px;
    width: 10px;
    border-radius: 15px;
  }
`;

const underlineStyles = `
  &:after{
    position: absolute;
    content: '';
    left: 0px;
    top: 51px;
    width: 100%;
    height: 4px;
    z-index: 1;
    background-color: #fff;
    @media screen and (max-width: 1050px) {
      left: -19px;
      top: 23px;
      height: 10px;
      width: 10px;
      border-radius: 15px;
    }
  }
`;

export const NavSideIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  @media screen and (max-width: 1050px) {
    color: #b5bfc8;
  }
`;

type StyledLinkProps = {
  last?: boolean;
};

export const StyledLink = styled(NavLink)<StyledLinkProps>`
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  height: 55px;
  margin: 0px;
  margin-left: ${(props) => (props.last ? 'auto' : '10px')};
  margin-right: ${(props) => (props.last ? '22px' : '10px')};
  padding-left: ${(props) => (props.last ? 'auto' : '10px')};
  padding-right: ${(props) => (props.last ? '22px' : '10px')};
  position: relative;
  white-space: nowrap;
  &:hover {
    color: #fff;
    text-decoration: none;
    ${underlineStyles}
  }
  &.active {
    ${underlineStyles};
  }

  @media screen and (max-width: 1050px) {
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #101840;
    &:hover {
      color: #101840;
    }
  }
`;
