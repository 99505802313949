import { FormLabel } from '../FormLabel/FormLabel';
import { StyledInput, StyledInputContainer } from './Input.styles';
import { InputProps } from './Input.types';

export const Input = ({
  label,
  name,
  type = 'text',
  value,
  defaultValue,
  onChange,
  placeholder = label,
  onKeyDown,
  required,
  disabled,
  displayAsRequired,
  customStyles,
}: InputProps) => {
  return (
    <StyledInputContainer style={customStyles}>
      {label && (
        <FormLabel
          htmlFor={name}
          label={label}
          disabled={disabled}
          required={displayAsRequired}
        />
      )}
      <StyledInput
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
      />
    </StyledInputContainer>
  );
};
