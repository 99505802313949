export const readFromLocalStorageOrDefault = <T>(
  name: string,
  defaultData: T,
): T => {
  const raw = localStorage.getItem(name);

  if (raw !== null) {
    return JSON.parse(raw) as T;
  }
  return defaultData;
};
