import { Badge, IconButton } from '@mui/material';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import React from 'react';
import { NotificationButtonProps } from './Notifications.types';

const NotificationButton = React.memo((props: NotificationButtonProps) => {
  return (
    <IconButton onClick={props.onClick} disabled={props.disabled}>
      <Badge
        badgeContent={props.disabled ? 0 : props.numberOfNotifications}
        color="error"
      >
        <NotificationsOutlinedIcon
          fontSize="small"
          style={{ color: '#fff', opacity: props.disabled ? '30%' : '100%' }}
        />
      </Badge>
    </IconButton>
  );
});

export default NotificationButton;
