import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin-bottom: 20px;
  width: 100%;
`;

export const StyledInput = styled.input`
  border: 1px solid #d7ddea;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  height: 40px;
  padding: 15px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;
