import { format } from 'date-fns';
import React from 'react';
import {
  NotificationContent,
  NotificationDictionary,
} from '../../../../interfaces/Notifications.types';
import {
  COMP_FOOTER_HEIGHT,
  COMP_HEADER_HEIGHT,
  NOTIFICATION_HEIGHT,
  SEPARATOR_HEIGHT,
} from '../Notifications.config';
import {
  NotificationsListContainer,
  StyledNotificationPopperContainer,
} from '../Notifications.styles';
import { NotificationFeedProps } from '../Notifications.types';
import NotificationStack from '../NotificationStack';
import {
  isDateWithin24Hours as isWithin24Hours,
  variantToNotificationTypeLookup,
} from '../utils';
import NotificationPopperFooter from './NotificationPopperFooter';
import NotificationPopperHeader from './NotificationPopperHeader';

const transformNotificationsContentForPopper = (
  content: NotificationContent,
): NotificationContent => ({
  ...content,
  dateString: format(new Date(content.dateString), 'dd/MM/yyyy  •  HH:mm'),
  variant: variantToNotificationTypeLookup[content.variant] ?? 'other',
});

const NotificationPopperContent = (props: NotificationFeedProps) => {
  const { feedContent, onReadOnClick, onReadAllClick, onNotificationClick } =
    props;

  const groupedNotifications =
    React.useMemo<NotificationDictionary>((): NotificationDictionary => {
      /* We divide notifications into 2 groups: Now and Earlier
    Now group contains notifications created in last 24h, earlier contains the rest
     */
      const grouped: NotificationDictionary = {
        'Last 24h': [],
        Earlier: [],
      };

      feedContent.forEach((notif) => {
        if (isWithin24Hours(new Date(notif.dateString))) {
          grouped['Last 24h'].push(
            transformNotificationsContentForPopper(notif),
          );
        } else {
          grouped.Earlier.push(transformNotificationsContentForPopper(notif));
        }
      });

      Object.keys(grouped).forEach((groupName) => {
        if (grouped[groupName].length === 0) {
          delete grouped[groupName];
        }
      });

      return grouped;
    }, [props]);

  const componentFullHeight = React.useMemo(() => {
    const amountOfNotifications = Math.min(5, feedContent.length);
    const amountOfSeparators = Object.keys(groupedNotifications).length;

    return (
      amountOfNotifications * (NOTIFICATION_HEIGHT + 1) +
      amountOfSeparators * SEPARATOR_HEIGHT +
      COMP_HEADER_HEIGHT +
      COMP_FOOTER_HEIGHT
    );
  }, [groupedNotifications]);

  const onNotificationReadCallback = () => {
    if (!onReadOnClick) {
      return undefined;
    }
    return (notification_pos: number) => {
      if (onReadOnClick) {
        return onReadOnClick(notification_pos);
      }
    };
  };

  return (
    <StyledNotificationPopperContainer
      style={{ height: `${componentFullHeight}px` }}
    >
      <NotificationPopperHeader
        height={`${COMP_HEADER_HEIGHT}px`}
        onReadAllClick={onReadAllClick}
      />
      <NotificationsListContainer>
        <NotificationStack
          separatorHeight={SEPARATOR_HEIGHT}
          notificationHeight={NOTIFICATION_HEIGHT}
          elementsDictionary={groupedNotifications}
          onNotificationRead={onNotificationReadCallback()}
          onNotificationClick={onNotificationClick}
        />
      </NotificationsListContainer>
      <NotificationPopperFooter height={`${COMP_FOOTER_HEIGHT}px`} />
    </StyledNotificationPopperContainer>
  );
};

export default NotificationPopperContent;
