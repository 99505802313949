import { Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

const FONT_FAMILY = 'Inter, Roboto, Helvetica, Arial, sans-serif';

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
    display: 'flex',
    height: '800px',
    border: 'none',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#F4F5F7',
      color: 'white',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-row': {
      background: 'white',
      '&:hover': {
        background: ({ onRowClick }) =>
          onRowClick ? 'rgba(25, 118, 210, 0.04)' : 'white',
        cursor: ({ onRowClick }) => (onRowClick ? 'pointer' : 'initial'),
      },
    },

    '& .name-cell': {
      fontWeight: 600,
    },
    '& .update-cell, .supervisor-cell': {
      color: '#9fa3b3',
    },
    '& .profile-cell': {
      fontWeight: 500,
    },
    '& .MuiDataGrid-cell': {
      fontSize: '13px',
      fontFamily: FONT_FAMILY,
    },
    '& .MuiDataGrid-footerContainer': {
      backgroundColor: '#f1f1f1',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      '& .MuiIconButton-root': {
        '& svg': {
          color: '#000000',
        },
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '11px',
      fontFamily: FONT_FAMILY,
      fontStyle: 'normal',
      color: '#9FA3B3',
      fontWeight: 500,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px',
    },
    '& .MuiIconButton-label': {
      fontSize: '13px',
    },
    '& .MuiButtonGroup-root': {
      gap: '5px',
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '16px',
      marginBottom: '0px',
    },
    '& .MuiTablePagination-selectLabel': {
      display: 'block',
      fontSize: '16px',
      marginBottom: '0px',
    },
    '& .MuiTablePagination-select': {
      fontSize: '16px',
      marginRight: '6px',
    },

    '& .MuiTablePagination-menuItem': {
      fontSize: '14px',
    },
    '& .MuiIconButton-root': {
      fontSize: '13px',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .lists-header': {
      paddingLeft: '34px',
    },
    '& .MuiDataGrid-cellContent': {
      fontSize: '13px',
      fontFamily: FONT_FAMILY,
    },
    '& .lists-main-column-cell': {
      fontWeight: 600,
      paddingLeft: '34px',
      cursor: 'pointer',
    },
    '& .lists-cell': {
      color: '#9fa3b3',
    },
  },
  dropdown: {
    '& .MuiMenuItem-root': {
      fontSize: 14,
    },
  },
}));

export const StyledPagination = styled(Pagination)`
  & .MuiPaginationItem-root {
    font-size: 14px;
  }
  & .MuiPaginationItem-root.Mui-selected {
    background: rgba(60, 194, 217, 0.3);
    color: #000000;
  }
`;
