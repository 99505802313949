import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';

type ButtonWrapperProps = {
  marginLeft?: string;
};

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  align-items: center;
  background-color: #3cc2d9;
  border: none;
  border-radius: 4px;
  color: white;
  display: flex;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-weight: 400;
  height: 46px;
  margin-left: ${({ marginLeft }) => {
    return marginLeft ? '18px' : '0';
  }};
  padding: 12px 16px 12px 11px;
  transition-duration: 0.2s;
  white-space: pre;

  &:hover {
    background-color: #5cd6eb;
    cursor: pointer;
  }
  &:active {
    background-color: #26a8be;
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  font-size: 24px;
`;
