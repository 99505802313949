import { createSlice } from '@reduxjs/toolkit';
import { EmployeeFilters } from '../../store/types/Employees.types';
import {
  EmployeeSliceInterface,
  EmployeeStatisticsType,
  EmployeeTableDataInterface,
  Technology,
} from '../../store/types/Store.candidates.types';
import { Currency } from '../../store/types/Store.types';
import { readFromLocalStorageOrDefault } from '../../utils/common';
import { employeeThunks } from './Employees.thunk';

const {
  getEmployeesThunk,
  // partialUpdateEmployeeThunk,
  updateEmployeeActivityThunk,
  createEmployeeThunk,
  getEmployeesWithValidExpThunk,
  getEmployeeThunk,
  getPublicEmployeesThunk,
  saveStateThunk,
  getSuggestedSourcesThunk,
  getClientListProfilesEmployeeThunk,
  getPartnerListCandidatesThunk,
  getExternalClientListCandidatesThunk,
  getClientListCandidatesThunk,
} = employeeThunks;

const initialState: EmployeeSliceInterface = {
  people: [],
  statistics: readFromLocalStorageOrDefault<EmployeeStatisticsType | null>(
    'employees/getEmployees',
    null,
  ),
  // this field (employee) is deprecated, for the love of god. DO NOT USE IT
  // use field "people" instead
  employee: {
    id: '',
    readable_id: '',
    first_name: '',
    last_name: '',
    profile: '',
    availability: '',
    rate_of_pay_to: '',
    rate_of_pay_currency: Currency.EUR,
    supervisor: '',
    updated_at: '',
    recruitee_link: '',
    english_level: null,
    cvs: [],
    career_start_date: '',
    technology_tags: [],
    is_active: false,
    technologies: [],
    source: '',
  },
  filters: {
    employee: readFromLocalStorageOrDefault<EmployeeFilters['employee']>(
      'employeeFilters',
      {
        search: '',
        owner: '',
        technologies: [],
        list: '',
      },
    ),
    sales: readFromLocalStorageOrDefault<EmployeeFilters['sales']>(
      'salesFilters',
      {
        search: '',
        owner: '',
        technologies: [],
        list: '',
      },
    ),
    client: readFromLocalStorageOrDefault<EmployeeFilters['client']>(
      'clientFilters',
      {
        search: '',
      },
    ),
  },
  employeesLoaded: false,
  suggestedSources: [],
  publicPeople: [],
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveStateThunk.fulfilled, (state, action) => {
      const data = action.payload;
      switch (data.employeeType) {
        case 'employee':
          state.filters.employee.search = data.search;
          state.filters.employee.owner = data.owner;
          state.filters.employee.technologies = data.technologies;
          state.filters.employee.list = data.list;
          break;
        case 'sales':
          state.filters.sales.search = data.search;
          state.filters.sales.owner = data.owner;
          state.filters.sales.technologies = data.technologies;
          state.filters.sales.list = data.list;
          break;
        case 'client':
          state.filters.client.search = data.search;
          break;
        default:
          throw Error(
            `Cannot find this type of employee: ${data.employeeType}`,
          );
      }
    });
    builder.addCase(getEmployeeThunk.fulfilled, (state, action) => {
      // MW: I use here an additional spread operator. I am scared that
      // JS could GC the "action.payload" data. If i am wrong and this is
      // redundant, please remove it
      const updatedEmployee = { ...action.payload };
      const employeeIdx = state.people.findIndex(
        (needle) => needle.id === updatedEmployee.id,
      );
      if (employeeIdx === -1) {
        throw new Error(
          `Cannot update employee: ${JSON.stringify(updatedEmployee)}`,
        );
      }
      state.people[employeeIdx] = updatedEmployee;
    });
    builder.addCase(getEmployeesThunk.fulfilled, (state, action) => {
      state.people = [...action.payload.employees_list];
      state.statistics = { ...action.payload.statistics };
      state.employeesLoaded = true;
    });
    builder.addCase(
      getEmployeesWithValidExpThunk.fulfilled,
      (state, action) => {
        state.peopleWithValidExp = action.payload.employees_list;
      },
    );
    builder.addCase(getPublicEmployeesThunk.fulfilled, (state, action) => {
      state.people = action.payload;
      state.employeesLoaded = true;
    });
    builder.addCase(createEmployeeThunk.fulfilled, (state, action) => {
      const updatedPeople = [...state.people];
      updatedPeople.unshift(action.payload as EmployeeTableDataInterface);
      state.people = updatedPeople;
    });
    builder.addCase(updateEmployeeActivityThunk.fulfilled, (state, action) => {
      const updatedPeople = [...state.people];
      const index = updatedPeople.findIndex(
        (person) => person.id === action.payload.id,
      );

      if (index === -1) {
        throw new Error(`cannot find employee: ${action.payload}`);
      }
      updatedPeople[index] = action.payload;
    });
    builder.addCase(getSuggestedSourcesThunk.fulfilled, (state, action) => {
      state.suggestedSources = action.payload;
    });
    builder.addCase(
      getClientListProfilesEmployeeThunk.fulfilled,
      (state, action) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const employee of action.payload) {
          let idx = state.people.findIndex((el) => el.id === employee.id);

          if (idx === -1) {
            idx = state.people.length;
          }
          employee.technologies = employee.technologies.map(
            (el: Technology) => el.name,
          );

          state.people[idx] = employee;
        }
      },
    );
    builder.addCase(
      getPartnerListCandidatesThunk.fulfilled,
      (state, action) => {
        state.publicPeople = action.payload;
      },
    );
    builder.addCase(
      getExternalClientListCandidatesThunk.fulfilled,
      (state, action) => {
        state.publicPeople = action.payload;
      },
    );
    builder.addCase(getClientListCandidatesThunk.fulfilled, (state, action) => {
      state.publicPeople = action.payload;
    });
  },
});

export const actions = { ...employeesSlice.actions };
export const { reducer } = employeesSlice;
