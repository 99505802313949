import { Checkbox, Tooltip } from '@mui/material';
import NotificationRadioButtonChecked from './icons/NotificationRadioButtonChecked';
import NotificationRadioButtonUnchecked from './icons/NotificationRadioButtonUnchecked';

const NotificationReadButton = ({
  onNotificationRead,
  checked,
}: {
  onNotificationRead: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}) => {
  return (
    <Tooltip title="Mark as read">
      <Checkbox
        checked={checked}
        icon={<NotificationRadioButtonUnchecked width="20px" />}
        checkedIcon={<NotificationRadioButtonChecked width="20px" />}
        onChange={onNotificationRead}
      />
    </Tooltip>
  );
};

export default NotificationReadButton;
