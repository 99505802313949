import styled from 'styled-components';

interface InputContainerProps {
  disabled?: boolean;
}

export const StyledInputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 10px;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
`;

export const StyledSelect = styled.select`
  border: 1px solid #696969;
  border-radius: 10px;
  height: 45px;
`;

export const StyledRadioLabel = styled.label`
  color: #004a62;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 13px;
  margin-bottom: 0px;
  padding-left: 10px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
