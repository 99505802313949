import styled from 'styled-components';

export const FormWrapper = styled.section`
  padding-bottom: 15px;
`;

export const AutocompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  margin-top: 20px;
`;
