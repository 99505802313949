/* eslint-disable complexity */
import {
  faTimes,
  faUnlockAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { routes } from '../../../../config/routes';
import { clientListCredentialsThunks } from '../../../../containers/ClientList/redux/ClientListCredentials.thunk';
import { isHrSalesOrAdmin, isPartner } from '../../../../Helpers';
import { ExternalClientCredentials } from '../../../../store/types/Store.clientList.types';
import { AppStore } from '../../../../store/types/Store.types';
import NavbarNotification from '../../Notifications/NavbarNotification';
import {
  CloseIcon,
  MainNavSection,
  NavSideIcon,
  StyledBreak,
  StyledLink,
  StyledLinks,
  StyledVerticalLinks,
  UserSection,
} from './NavbarItems.styles';
import { NavbaritemsProps } from './NavbarItems.types';

export const NavbarItems = ({
  id,
  collapsible,
  user,
  externalClientAuthData,
  dropdownToggler,
}: NavbaritemsProps) => {
  const Component = collapsible ? StyledVerticalLinks : StyledLinks;
  const dispatch = useDispatch();
  const unregisteredLogout = () => {
    dispatch(clientListCredentialsThunks.logoutThunk() as unknown as AnyAction);
  };

  const externalClientCredentials = useSelector<
    AppStore,
    ExternalClientCredentials
  >((state) => state.clientListRelated.credentials);

  return (
    <Component id={id}>
      {Component === StyledVerticalLinks && (
        <CloseIcon icon={faTimes} onClick={dropdownToggler} />
      )}
      {!externalClientAuthData && user ? (
        <>
          <MainNavSection>
            <StyledLink
              end
              to={{
                pathname: routes.main.path,
              }}
              onClick={dropdownToggler}
            >
              Home
            </StyledLink>
            {isHrSalesOrAdmin(user.user) && (
              <StyledLink
                to={{
                  pathname: routes.employees.path,
                }}
                onClick={dropdownToggler}
              >
                Candidates
              </StyledLink>
            )}
            {(isHrSalesOrAdmin(user.user) || isPartner(user.user)) && (
              <StyledLink
                to={{
                  pathname: routes.listsOfCandidatesForSharing.path,
                }}
                onClick={dropdownToggler}
              >
                Lists
              </StyledLink>
            )}
            {(isHrSalesOrAdmin(user.user) || isPartner(user.user)) && (
              <StyledLink
                to={{
                  pathname: routes.leads.path,
                }}
                onClick={dropdownToggler}
              >
                Leads
              </StyledLink>
            )}
            {isHrSalesOrAdmin(user.user) && (
              <StyledLink
                to={generatePath(routes.stats.path, {
                  statsTab: 'leads',
                })}
                onClick={dropdownToggler}
              >
                Stats
              </StyledLink>
            )}
          </MainNavSection>
          {Component === StyledLinks ? (
            <UserSection>
              <NavbarNotification />
              {user.user?.is_admin && (
                <StyledLink
                  to={{
                    pathname: routes.users.path,
                  }}
                  onClick={dropdownToggler}
                >
                  Users
                </StyledLink>
              )}
              <StyledLink
                to={{
                  pathname: routes.myProfile.path,
                }}
                onClick={dropdownToggler}
              >
                My profile
                <NavSideIcon icon={faUserCircle} />
              </StyledLink>
              <StyledLink
                to={{
                  pathname: routes.logout.path,
                }}
              >
                Log out
                <NavSideIcon icon={faUnlockAlt} />
              </StyledLink>
            </UserSection>
          ) : (
            <>
              <MainNavSection>
                {user.user?.is_admin && (
                  <StyledLink
                    to={{
                      pathname: routes.users.path,
                    }}
                    onClick={dropdownToggler}
                  >
                    Users
                  </StyledLink>
                )}
              </MainNavSection>
              <StyledBreak />
              <UserSection>
                <StyledLink
                  to={{
                    pathname: routes.myProfile.path,
                  }}
                  onClick={dropdownToggler}
                >
                  My profile
                  <NavSideIcon icon={faUserCircle} />
                </StyledLink>
                <StyledLink
                  to={{
                    pathname: routes.logout.path,
                  }}
                >
                  Log out
                  <NavSideIcon icon={faUnlockAlt} />
                </StyledLink>
              </UserSection>
            </>
          )}
        </>
      ) : (
        <>
          {externalClientAuthData && (
            <>
              <StyledBreak />
              <UserSection>
                <StyledLink
                  to={generatePath(routes.clientListLogin.path, {
                    id: externalClientCredentials.id,
                  })}
                  onClick={unregisteredLogout}
                >
                  Log out
                  <NavSideIcon icon={faUnlockAlt} />
                </StyledLink>
              </UserSection>
            </>
          )}
        </>
      )}
    </Component>
  );
};
