import { buttonsColors } from './Button.config';
import { ButtonWrapper, InputButtonWrapper } from './Button.styles';
import { ButtonProps } from './Button.types';

export function Button({
  children,
  action,
  buttonType,
  color = buttonsColors.default,
  fullWidth,
  disabled = false,
}: ButtonProps) {
  if (buttonType === 'submit') {
    return <InputButtonWrapper color={color} />;
  }
  return (
    <ButtonWrapper
      onClick={() => action && action()}
      {...{ color, fullWidth, disabled }}
      type={buttonType}
    >
      {children}
    </ButtonWrapper>
  );
}
