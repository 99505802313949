import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, AutocompleteProps } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormLabel } from '../FormLabel/FormLabel';
import {
  StyledInputContainer,
  StyledSpan,
  useStyles,
} from './AutocompleteInput.styles';
import { AutocompleteInputProps } from './AutocompleteInput.types';
import { AutocompleteLoader } from './AutocompleteLoader';

export const AutocompleteInput = <K, T extends object>({
  label,
  name,
  options,
  value,
  handleInputChange,
  handleCustomOption,
  handleKeyDown,
  required,
  freeSolo,
  disabled = false,
  disableCloseOnSelect,
  disableClearable,
  displayAsRequired,
  customStyles,
  inputValue,
  ...props
}: AutocompleteInputProps<
  AutocompleteProps<T, boolean, undefined, boolean, 'div'>,
  K
>) => {
  const classes = useStyles();
  const isFieldFilled =
    (inputValue?.length ? inputValue?.length > 0 : false) ||
    ((value as string)?.length ? (value as string)?.length > 0 : false);
  return (
    <StyledInputContainer style={customStyles}>
      {label && (
        <FormLabel
          htmlFor={name}
          label={label}
          disabled={disabled}
          required={displayAsRequired}
        />
      )}
      <Autocomplete
        id={name}
        className={classes.root}
        value={value}
        inputValue={inputValue}
        disablePortal
        options={options}
        renderOption={(prop, option) => (
          <StyledSpan {...prop} id={name}>
            {option}
          </StyledSpan>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleCustomOption}
            name={name}
            placeholder="..."
            required
          />
        )}
        onKeyDown={handleKeyDown}
        renderTags={() => <></>}
        {...props}
        onChange={props.onChange ? props.onChange : handleInputChange}
        freeSolo={freeSolo}
        disabled={disabled}
        disableCloseOnSelect={disableCloseOnSelect}
        noOptionsText={<AutocompleteLoader />}
        disableClearable={disableClearable || !isFieldFilled}
        clearIcon={<ClearIcon fontSize="small" id={name} />}
      />
    </StyledInputContainer>
  );
};
