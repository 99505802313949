import React from 'react';
import company_logo from '../images/company_logo.png';
import company_motto from '../images/company_motto.png';
import {
  StyledNav,
  StyledNavImage,
  StyledCompanyLogoWrapper,
  StyledCompanyMottoWrapper,
  StyledContainer,
  StyledButtonsContainer,
  StyledFormTitle,
  GlobalStyle,
} from './IdegoDialog.styles';
import { Button } from '../Button/Button.component';

export const IdegoDialog = React.forwardRef(
  ({ children, dialogTitle, buttonsConfig, showLogo }, ref) => {
    return (
      <>
        <GlobalStyle />
        <StyledContainer ref={ref} tabIndex={-1}>
          {showLogo && (
            <StyledNav>
              <StyledCompanyLogoWrapper>
                <StyledNavImage src={company_logo} alt="company-logo" />
              </StyledCompanyLogoWrapper>
              <StyledCompanyMottoWrapper>
                <StyledNavImage src={company_motto} alt="company-logo" />
              </StyledCompanyMottoWrapper>
            </StyledNav>
          )}
          <StyledFormTitle>{dialogTitle}</StyledFormTitle>
          {children}
          <StyledButtonsContainer>
            {buttonsConfig.primaryButton && (
              <Button
                color={buttonsConfig.primaryButton.color}
                action={buttonsConfig.primaryButton.action}
                buttonType={buttonsConfig.primaryButton.type}
              >
                {buttonsConfig.primaryButton.label}
              </Button>
            )}
            {buttonsConfig.secondaryButton && (
              <Button
                color={buttonsConfig.secondaryButton.color}
                action={buttonsConfig.secondaryButton.action}
              >
                {buttonsConfig.secondaryButton.label}
              </Button>
            )}
          </StyledButtonsContainer>
        </StyledContainer>
      </>
    );
  },
);
