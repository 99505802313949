export const modalSizes = {
  narrow: '459px',
  auto: 'auto',
  wide: '709px',
};

export const wideModals = [
  'archiving',
  'deleting',
  'addingRequest',
  'deletingRequest',
  'deactivating',
  'sharing',
  'candidateDetails',
  'showDescription',
  'editingRequestModal',
  'deleteSuggestedCandidate',
  'showDescription',
];
